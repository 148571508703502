<template>
    <div class="toast" :class="`toast--${item.type}`">
        <div class="icon">
            <svg-icon :icon-class="messageIcon"></svg-icon>
        </div>
        <div class="message" v-html="decodedMessage"></div>
        <!-- <div class="close">
					<svg-icon icon-class="cancel"></svg-icon>
				</div> -->
        <div class="toast-progressbar" :style="{ width: `${progress}%` }"></div>
    </div>
</template>

<script>
export default {
    props: ['item', 'progress'],
    data: () => ({
        width: 0,
        interval: null,
    }),
    methods: {},
    computed: {
        messageIcon() {
            switch (this.item.type) {
                case 'info':
                default:
                    return 'information-outline';

                case 'error':
                    return 'notification1';

                case 'warn':
                    return 'question_mark';

                case 'success':
                    return 'checked';
            }
        },
        decodedMessage() {
            return decodeURIComponent(this.item.message);
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.toast {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    max-width: 600px;
    min-width: 200px;
    font-weight: 400;
    background-color: #ffffff;
    padding: 15px;
    margin: 15px;
    border-radius: 0 5px 5px 0;
    box-shadow: 0 5px 3px #3e3e3e33;
    z-index: 2999 !important;

    .toast-progressbar {
        position: absolute;
        height: 4px;
        width: 0%;
        left: 0px;
        bottom: 0px;
        opacity: 0.3;
        -webkit-transition: width 0s ease;
        -moz-transition: width 0s ease;
        -ms-transition: width 0s ease;
        -o-transition: width 0s ease;
        transition: width 0s ease;
    }

    &.toast--error {
        color: #f73531;
        border-left: 5px solid #f73531;

        .toast-progressbar {
            background-color: #f73531;
        }
    }

    &.toast--info {
        color: #2f9cf4;
        border-left: 5px solid #2f9cf4;

        .toast-progressbar {
            background-color: #2f9cf4;
        }
    }

    &.toast--warn {
        color: #ff9326;
        border-left: 5px solid #ff9326;

        .toast-progressbar {
            background-color: #ff9326;
        }
    }

    &.toast--success {
        color: #57b45b;
        border-left: 5px solid #57b45b;

        .toast-progressbar {
            background-color: #57b45b;
        }
    }

    ::v-deep .svg-icon {
        font-size: 1.6em;
    }

    .icon {
        display: inline-flex;
        align-self: center;
        padding: 5px;
    }

    .message {
        display: block;
        align-self: center;
        padding: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .close {
        display: inline-flex;
        align-self: center;
        padding: 5px;
        cursor: pointer;
    }
}
</style>
